import { useState, useEffect } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { PubSub } from 'aws-amplify';
import {Amplify} from '@aws-amplify/core';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { AutoTextSize } from 'auto-text-size'

export function TurretFiredImageView(props) {

  const location = useLocation();

  // const [broadcasterId, setBroadcasterId] = useState("");
  const [displayName, setDisplayName] = useState(null);
  useEffect(() => {
    Amplify.addPluggable(new AWSIoTProvider({
       aws_pubsub_region: 'us-east-2',
       aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
     }));

    var broadcasterId = location.pathname.split("/").pop()
    subscribeToUserFiredUpdates(broadcasterId);
  }, []);


  function subscribeToUserFiredUpdates(twitchChannelId) {

    

    return PubSub.subscribe("user-fired/" + String(twitchChannelId) + "/+").subscribe({
        next: data => {
          console.log("[FIRE SUB]",'Message received', data)
          setDisplayName(data.value.displayName)
          setTimeout(function(){
            setDisplayName(null)
          }, 30000);
        },
        error: error => console.error("[FIRE SUB]",error),
        complete: () => console.log("[FIRE SUB]",'Done'),
      });
  }

  

  return (
    <div className="col-12 min-vh-100">

    {(displayName && 
          // <h5 style={{color:"#33FF33"}} bold="true">{displayName} fired Stream Turret!!!</h5>
      <AutoTextSize>
        <p style={{"color": "#33FF33","text-shadow": "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>{displayName} fired Stream Turret!!!</p>
      </AutoTextSize>
          )
    }
    

    </div>
  )

}