
import { API } from '@aws-amplify/api';
import {Auth} from '@aws-amplify/auth';
import { PubSub } from 'aws-amplify';
import {Amplify} from '@aws-amplify/core';
// import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { v4 as uuidv4 } from 'uuid';


export const apiPaths = {
  requestControl:"/requestControl",
  payment:"/payment",
  paymentIntent:"/paymentIntent",
  driveWaitlist:"/driveWaitlist",
  gameWaitlist:"/gameWaitlist",
  cancelDrive:"/cancelDrive",
  createProduct:"/createProduct",
  roverUnits:"/roverUnits",
  newCreatorAccount:"/creator/account/create",
  purchaselink:"/purchase/link",
  purchaseTrial:"/purchase/trial",
  connectToRoverMsg:"/rover/message/connect",
  addRover:"/rover/add",
  updateRoverEnvironment:"/rover/updateEnvironment",
  archiveProduct:"/product/archive",
  joinRoverEnvironmentWaitlist:"/roverEnvironment/joinWaitlist",
  roverEnvironmentSetupPubsub:"/roverEnvironment/setupPubsub",
  turretStoreVerifyUser:"/turretStore/verifyUser",
  turretStoreGetDepositLink:"/turretStore/getDepositLink",
  turretVoteVoterInfo:"/turretVote/getVoterInfo",
  turretVoteVote:"/turretVote/vote",
  turretVoteFindStreamer:"/turretVote/findStreamer"
}


export const DriveState = {
  unauth: "unauth",
	viewing: "viewing",
	requested: "requested",
	ready: "ready",
	driving: "driving"
}

export function turretVoteFindStreamer(streamerName) {


    var body = {
      streamerName: streamerName
    }
    console.log("turretVoteFindStreamer",body)

    return apiPostPromiseUnauth(body, apiPaths.turretVoteFindStreamer)

}

export function turretVoteVote(token, streamerId) {


    var body = {
      token: token,
      streamerId: streamerId
    }
    console.log("turretVoteVoterInfo",body)

    return apiPostPromiseUnauth(body, apiPaths.turretVoteVote)

}

export function turretVoteGetVoterInfo(token) {


    var body = {
      token: token,
    }
    console.log("turretVoteVoterInfo",body)

    return apiPostPromiseUnauth(body, apiPaths.turretVoteVoterInfo)

}


export function turretStoreGetDepositLink(token) {


    var body = {
      token: token,
    }
    console.log("turretStoreGetDepositLink",body)

    return apiPostPromiseUnauth(body, apiPaths.turretStoreGetDepositLink)

}


export function roverEnvironmentSetupPubsub(roverEnvironmentId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverEnvironmentId: roverEnvironmentId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.roverEnvironmentSetupPubsub, true)
    .then((data) => {
      console.log("roverEnvironmentSetupPubsub",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })
}

export function joinRoverEnvironmentWaitlist(roverEnvironmentId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverEnvironmentId: roverEnvironmentId,
      userId: userId,
      username: username
    }

    return apiPostPromise(body, apiPaths.joinRoverEnvironmentWaitlist)
    .then((data) => {
      console.log("updated rover environment",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })
}

export function updateRoverEnvironment(roverId, environmentId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId: roverId,
      environmentId: environmentId
    }

    return apiPostPromise(body, apiPaths.updateRoverEnvironment)
    .then((data) => {
      console.log("updated rover environment",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })
}

export function addRover(roverId,keyHash) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId: roverId,
      keyHash: keyHash,
      userId: userId,
      username: username
    }

    return apiPostPromise(body, apiPaths.addRover)
    .then((data) => {
      console.log("created new rover",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })

}

export function connectToRoverMsg(roverId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId: roverId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.connectToRoverMsg, true)
    .then((data) => {
      console.log("msg approved",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })

}

export function newCreatorAccount() {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username

    var body = {
      userId: userId
    }

    return apiPostPromise(body, apiPaths.newCreatorAccount)
    .then((data) => {
      console.log("newCreatorAccount",data)
      return {
        success: true,
        url: data.url,
        object: data.object
      }
    }).catch((error) => {
      return {
        success: true,
        url: null,
        object: null
      }
    })
  })

}

export function purchaseTrial(productId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      productId: productId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.purchaseTrial)
    .then((data) => {
      console.log("purchase Trial",data.secret)
      return Promise.resolve({
        success: true
      })
    }).catch((error) => {
      return Promise.resolve({success: false})
    })
  })

}

export function getPaymentLink(productId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      productId: productId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.purchaselink)
    .then((data) => {
      console.log("purhcaselink",data.secret)
      return Promise.resolve({
        success: true,
        url:data.secret
      })
    }).catch((error) => {
      return Promise.resolve({success: false})
    })
  })

}

export function getPaymentIntent(productId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      productId: productId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.paymentIntent)
    .then((data) => {
      console.log("paymentIntent",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })

}

export function joinDriveWaitlist(roverId) {
  console.log("[DRV] request to drive")

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username

    var reqBody = {
      request:"join",
      userId: userId,
      roverId: roverId,
      username: username
    }

    return apiPostPromise(reqBody, apiPaths.driveWaitlist)
  })
  .then((data) => {

    console.log("[DRV]",data)
    return {success:true}

  }).catch((error) => {
    console.log("[DRV]",error)
    return {success:false}

  })
}

export function leaveDriveWaitlist(roverId, driveRequestId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username

    var reqBody = {
      request:"leave",
      userId: userId,
      roverId: roverId,
      username: username,
      requestId: driveRequestId
    }

    return apiPostPromise(reqBody, apiPaths.driveWaitlist)
  })
  .then((data) => {

    console.log("[DRV]",data)
    return {success:true}

  }).catch((error) => {
    console.log("[DRV]",error)
    return {success:false}

  })
}

export function joinGameWaitlist(gameEnvironmentId) {
  console.log("[PLAY] request to play")

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username

    var reqBody = {
      request:"join",
      userId: userId,
      gameEnvironmentId: gameEnvironmentId,
      username: username
    }

    return apiPostPromise(reqBody, apiPaths.gameWaitlist)
  })
  .then((data) => {

    console.log("[PLAY]",data)
    return {success:true}

  }).catch((error) => {
    console.log("[PLAY]",error)
    return {success:false}

  })
}

export function leaveGameWaitlist(gameEnvironmentId, gameRequestId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username

    var reqBody = {
      request:"leave",
      userId: userId,
      gameEnvironmentId: gameEnvironmentId,
      username: username,
      requestId: gameRequestId
    }

    return apiPostPromise(reqBody, apiPaths.gameWaitlist)
  })
  .then((data) => {

    console.log("[DRV]",data)
    return {success:true}

  }).catch((error) => {
    console.log("[DRV]",error)
    return {success:false}

  })
}

// export function addPluggable() {
//   Amplify.addPluggable(new AWSIoTProvider({
//      aws_pubsub_region: 'us-east-2',
//      aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
//    }));
// }

export function startPubsub(roverId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username

    const body = {
      request:"AUTHORIZE",
      roverId: roverId,
      userId: userId
    }

    console.log("[PUBSUB] startPubsub", body)

    return apiPostPromise(body, apiPaths.requestControl, true)
    .then((data) => {
      // Apply plugin with configuration
      console.log("[PUBSUB]",data)
      if (data.authorized) {
        return {authorized: true, tempTopicId: data.tempTopicId}
      } else {
        return {authorized:false, tempTopicId: ""}
      }
    }).catch((error) => {
      return {authorized:false, tempTopicId: ""}
    })
  })

}


export function endPubsub(roverId, tempTopicId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username

    const body = {
      request:"REMOVE",
      roverId: roverId,
      tempTopicId: tempTopicId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.requestControl, true)
    .then((data) => {
      console.log("[PUBSUB]",data)
      return {success: true}
    }).catch((error) => {
      console.log("[PUBSUB]",error)
      return {success: false}
    })
  })
}

export function cancelDrive(roverId, tempTopicId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username

    const body = {
      roverId: roverId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.cancelDrive, true)
    .then((data) => {
      console.log(data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })

  })
}


export function subscribeToUserFiredUpdates(twitchChannelId) {

  Amplify.addPluggable(new AWSIoTProvider({
     aws_pubsub_region: 'us-east-2',
     aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
   }));

  return PubSub.subscribe("user-fired/" + "810156327/+").subscribe({
      next: data => {
        console.log("[FIRE SUB]",'Message received', data)
      },
      error: error => console.error("[FIRE SUB]",error),
      complete: () => console.log("[FIRE SUB]",'Done'),
    });
}


export function subscribeToGameUpdates(setShotsFired, setHealth) {

  Amplify.addPluggable(new AWSIoTProvider({
     aws_pubsub_region: 'us-east-2',
     aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
   }));

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    // console.log("[GAME SUB]","user/gameupdate/"+userId)
    console.log("[GAME SUB]","gameupdate/" + userId)
    return PubSub.subscribe("gameupdate/" + userId).subscribe({
      next: data => {
        setShotsFired(data.value.shots)
        setHealth(data.value.health)
        console.log("[GAME SUB]",'Message received', data.value.shots)
      },
      error: error => console.error("[GAME SUB]",error),
      complete: () => console.log("[GAME SUB]",'Done'),
    });
  })
}


export function createProduct(roverId,roverName,productType,units,price,freeTrial) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId:roverId,
      userId:userId,
      username:username,
      units:units,
      price:price,
      productType:productType,
      freeTrial: freeTrial,
      roverName: roverName
    }
    console.log("new product",body)

    return apiPostPromise(body, apiPaths.createProduct)
    .then((data) => {
      console.log(data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })
}

export function archiveProduct(productId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    var body = {
      productId:productId,
      userId:userId
    }
    console.log("archive product",body)

    return apiPostPromise(body, apiPaths.archiveProduct)
    .then((data) => {
      console.log(data)
      return {success: true}
    }).catch((error) => {
      console.log(error)
      return {success: false}
    })
  })
}

export function getUnitsByRover(roverId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId:roverId,
      userId:userId
    }

    return apiPostPromise(body, apiPaths.roverUnits)
  })
}

export function apiPostPromiseUnauth(body, apiPath, addIdentityId = false) {

  const apiName = "rover"

  // const apiInit = {
  //       body: body,
  //       headers: {
  //         'Content-Type' : 'application/json',
  //       }
  //   };

    const apiInit = {
        body: body,
    };

  return API.post(apiName, apiPath, apiInit)

}


export function apiPostPromise(body, apiPath, addIdentityId = false) {

  const apiName = "rover"

  return Promise.all([Auth.currentSession(), Auth.currentUserCredentials(), Auth.currentAuthenticatedUser()])
  .then((res) => {
    // let accessToken = res[0].getAccessToken()
    let accessToken = res[0].getIdToken()
    // console.log("authUser",res[2])
    // let jwt = res[2].signInUserSession.idToken.jwtToken
    let jwt = accessToken.getJwtToken()
    let identityId = res[1].identityId

    if (addIdentityId) {
      body["identityId"] = identityId
    }

    const apiInit = {
        body: body,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type' : 'application/json',
        }
    };

    // const apiInit = {
    //     body: body,
    // };

    return API.post(apiName, apiPath, apiInit)

  })

}
