/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCreatorAccount = /* GraphQL */ `
  query GetCreatorAccount($userId: String!) {
    getCreatorAccount(userId: $userId) {
      id
      userId
      stripeAccountId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCreatorAccounts = /* GraphQL */ `
  query ListCreatorAccounts(
    $userId: String
    $filter: ModelCreatorAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreatorAccounts(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        stripeAccountId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getViewRoverSession = /* GraphQL */ `
  query GetViewRoverSession($id: ID!) {
    getViewRoverSession(id: $id) {
      id
      roverId
      username
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listViewRoverSessions = /* GraphQL */ `
  query ListViewRoverSessions(
    $filter: ModelViewRoverSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewRoverSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roverId
        username
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const streamTurretVoteByVoterId = /* GraphQL */ `
  query StreamTurretVoteByVoterId(
    $voterId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStreamTurretVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    streamTurretVoteByVoterId(
      voterId: $voterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        voterId
        voterName
        streamerId
        streamerName
        voter {
          voterId
          voterName
          votesUsed
          votesAvailable
          voterEmail
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        streamTurretVoterVotesId
        owner
      }
      nextToken
    }
  }
`;
export const streamTurretVoteByStreamerId = /* GraphQL */ `
  query StreamTurretVoteByStreamerId(
    $streamerId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStreamTurretVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    streamTurretVoteByStreamerId(
      streamerId: $streamerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        voterId
        voterName
        streamerId
        streamerName
        voter {
          voterId
          voterName
          votesUsed
          votesAvailable
          voterEmail
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        streamTurretVoterVotesId
        owner
      }
      nextToken
    }
  }
`;
export const purchasesByUserRoverAndDate = /* GraphQL */ `
  query PurchasesByUserRoverAndDate(
    $userRoverId: String!
    $purchaseDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchasesByUserRoverAndDate(
      userRoverId: $userRoverId
      purchaseDate: $purchaseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userRoverId
        stripePaymentIntentId
        stripeCheckoutSessionId
        productId
        roverId
        productType
        unitsPurchased
        purchaseDate
        sellerId
        owners
        purchaseAmount
        freeTrial
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const balanceByUserRoverAndDate = /* GraphQL */ `
  query BalanceByUserRoverAndDate(
    $userRoverId: String!
    $lastPurchaseDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBalanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    balanceByUserRoverAndDate(
      userRoverId: $userRoverId
      lastPurchaseDate: $lastPurchaseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roverId
        userId
        userRoverId
        cameraSeconds
        driveSeconds
        messages
        lastPurchaseDate
        lastPurchaseId
        lastUnitsUnitsConsumedDate
        lastUnitsUnitsConsumedId
        owners
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const unitsConsumedByUserRoverAndDate = /* GraphQL */ `
  query UnitsConsumedByUserRoverAndDate(
    $userRoverId: String!
    $dateConsumed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUnitsConsumedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitsConsumedByUserRoverAndDate(
      userRoverId: $userRoverId
      dateConsumed: $dateConsumed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        roverId
        userRoverId
        productType
        unitsConsumed
        dateConsumed
        sellerId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const projectileCountByRoverAndDate = /* GraphQL */ `
  query ProjectileCountByRoverAndDate(
    $roverId: String!
    $countDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectileCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectileCountByRoverAndDate(
      roverId: $roverId
      countDate: $countDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roverId
        count
        countDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const twitchTransactionsByTwitchUserlId = /* GraphQL */ `
  query TwitchTransactionsByTwitchUserlId(
    $twitchUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTwitchTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    twitchTransactionsByTwitchUserlId(
      twitchUserId: $twitchUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionId
        exp
        transactionDate
        domainId
        skuCategory
        sku
        displayName
        costBits
        twitchUserId
        twitchChannelId
        twitchUserChannelId
        roverId
        createdAt
        updatedAt
        owners
      }
      nextToken
    }
  }
`;
export const twitchTransactionsByTwitchChannelId = /* GraphQL */ `
  query TwitchTransactionsByTwitchChannelId(
    $twitchChannelId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTwitchTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    twitchTransactionsByTwitchChannelId(
      twitchChannelId: $twitchChannelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionId
        exp
        transactionDate
        domainId
        skuCategory
        sku
        displayName
        costBits
        twitchUserId
        twitchChannelId
        twitchUserChannelId
        roverId
        createdAt
        updatedAt
        owners
      }
      nextToken
    }
  }
`;
export const twitchTransactionsByTwitchUserChannelId = /* GraphQL */ `
  query TwitchTransactionsByTwitchUserChannelId(
    $twitchUserChannelId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTwitchTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    twitchTransactionsByTwitchUserChannelId(
      twitchUserChannelId: $twitchUserChannelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionId
        exp
        transactionDate
        domainId
        skuCategory
        sku
        displayName
        costBits
        twitchUserId
        twitchChannelId
        twitchUserChannelId
        roverId
        createdAt
        updatedAt
        owners
      }
      nextToken
    }
  }
`;
export const getRover = /* GraphQL */ `
  query GetRover($id: ID!) {
    getRover(id: $id) {
      id
      name
      roverChannel
      online
      isOnline
      connected
      owner
      type
      ownerUsername
      roverEnvironmentId
      twitchChannelId
      twitchProduct
      isLiveTwitch
      twitchBroadcasterOpaqueId
      createdAt
      updatedAt
    }
  }
`;
export const listRovers = /* GraphQL */ `
  query ListRovers(
    $filter: ModelRoverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRovers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        roverChannel
        online
        isOnline
        connected
        owner
        type
        ownerUsername
        roverEnvironmentId
        twitchChannelId
        twitchProduct
        isLiveTwitch
        twitchBroadcasterOpaqueId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roversByUsername = /* GraphQL */ `
  query RoversByUsername(
    $ownerUsername: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRoverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roversByUsername(
      ownerUsername: $ownerUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        roverChannel
        online
        isOnline
        connected
        owner
        type
        ownerUsername
        roverEnvironmentId
        twitchChannelId
        twitchProduct
        isLiveTwitch
        twitchBroadcasterOpaqueId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roversByTwitchChannelId = /* GraphQL */ `
  query RoversByTwitchChannelId(
    $twitchChannelId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRoverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roversByTwitchChannelId(
      twitchChannelId: $twitchChannelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        roverChannel
        online
        isOnline
        connected
        owner
        type
        ownerUsername
        roverEnvironmentId
        twitchChannelId
        twitchProduct
        isLiveTwitch
        twitchBroadcasterOpaqueId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStreamTurretVoteCount = /* GraphQL */ `
  query GetStreamTurretVoteCount($streamerId: String!) {
    getStreamTurretVoteCount(streamerId: $streamerId) {
      streamerId
      streamerName
      streamerDisplayName
      totalVotes
      contestName
      didWinTurret
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listStreamTurretVoteCounts = /* GraphQL */ `
  query ListStreamTurretVoteCounts(
    $streamerId: String
    $filter: ModelStreamTurretVoteCountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStreamTurretVoteCounts(
      streamerId: $streamerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        streamerId
        streamerName
        streamerDisplayName
        totalVotes
        contestName
        didWinTurret
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const streamTurretVoteCountByContestNumberAndTotalVotes = /* GraphQL */ `
  query StreamTurretVoteCountByContestNumberAndTotalVotes(
    $contestName: String!
    $totalVotes: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStreamTurretVoteCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    streamTurretVoteCountByContestNumberAndTotalVotes(
      contestName: $contestName
      totalVotes: $totalVotes
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        streamerId
        streamerName
        streamerDisplayName
        totalVotes
        contestName
        didWinTurret
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!, $activeState: String!) {
    getProduct(id: $id, activeState: $activeState) {
      id
      roverId
      activeState
      number
      price
      type
      stripeProductId
      stripePriceId
      owner
      username
      freeTrial
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $id: ID
    $activeState: ModelStringKeyConditionInput
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      id: $id
      activeState: $activeState
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roverId
        activeState
        number
        price
        type
        stripeProductId
        stripePriceId
        owner
        username
        freeTrial
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDriveRequest = /* GraphQL */ `
  query GetDriveRequest($roverId: String!) {
    getDriveRequest(roverId: $roverId) {
      id
      roverId
      userId
      username
      requestDate
      createdAt
      updatedAt
    }
  }
`;
export const listDriveRequests = /* GraphQL */ `
  query ListDriveRequests(
    $roverId: String
    $filter: ModelDriveRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDriveRequests(
      roverId: $roverId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roverId
        userId
        username
        requestDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuthorizedDriver = /* GraphQL */ `
  query GetAuthorizedDriver($roverId: String!) {
    getAuthorizedDriver(roverId: $roverId) {
      id
      roverId
      userId
      username
      expirationDate
      createdAt
      updatedAt
    }
  }
`;
export const listAuthorizedDrivers = /* GraphQL */ `
  query ListAuthorizedDrivers(
    $roverId: String
    $filter: ModelAuthorizedDriverFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAuthorizedDrivers(
      roverId: $roverId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roverId
        userId
        username
        expirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const authorizedDriverByUserId = /* GraphQL */ `
  query AuthorizedDriverByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAuthorizedDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    authorizedDriverByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roverId
        userId
        username
        expirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoverEnvironment = /* GraphQL */ `
  query GetRoverEnvironment($id: ID!) {
    getRoverEnvironment(id: $id) {
      id
      name
      gameTypeString
      ownerUsername
      owner
      isLive
      roverIds
      createdAt
      updatedAt
    }
  }
`;
export const listRoverEnvironments = /* GraphQL */ `
  query ListRoverEnvironments(
    $filter: ModelRoverEnvironmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoverEnvironments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        gameTypeString
        ownerUsername
        owner
        isLive
        roverIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gameEnvironmentsByUsername = /* GraphQL */ `
  query GameEnvironmentsByUsername(
    $ownerUsername: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRoverEnvironmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameEnvironmentsByUsername(
      ownerUsername: $ownerUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        gameTypeString
        ownerUsername
        owner
        isLive
        roverIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoverEnvironmentRequest = /* GraphQL */ `
  query GetRoverEnvironmentRequest(
    $roverEnvironmentId: String!
    $requestDate: AWSDateTime!
  ) {
    getRoverEnvironmentRequest(
      roverEnvironmentId: $roverEnvironmentId
      requestDate: $requestDate
    ) {
      id
      roverEnvironmentId
      userId
      username
      requestDate
      createdAt
      updatedAt
    }
  }
`;
export const listRoverEnvironmentRequests = /* GraphQL */ `
  query ListRoverEnvironmentRequests(
    $roverEnvironmentId: String
    $requestDate: ModelStringKeyConditionInput
    $filter: ModelRoverEnvironmentRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoverEnvironmentRequests(
      roverEnvironmentId: $roverEnvironmentId
      requestDate: $requestDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roverEnvironmentId
        userId
        username
        requestDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const playerRoversByGameId = /* GraphQL */ `
  query PlayerRoversByGameId(
    $gameId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerRoverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playerRoversByGameId(
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameId
        roverId
        userId
        username
        teamName
        matchDate
        roverKilled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gamesbyEnvironmentId = /* GraphQL */ `
  query GamesbyEnvironmentId(
    $environmentId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gamesbyEnvironmentId(
      environmentId: $environmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        environmentId
        numberOfTeams
        gameStartTime
        gameEndTime
        gameStarted
        gameComplete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gameStatsbyEnvironmentId = /* GraphQL */ `
  query GameStatsbyEnvironmentId(
    $roverEnvironmentId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameStatsbyEnvironmentId(
      roverEnvironmentId: $roverEnvironmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roverEnvironmentId
        gameId
        userId
        username
        roverId
        playerRoverId
        winner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
