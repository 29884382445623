import { BrowserRouter, Route, Routes, Link, Navigate, useMatch, useLocation, useSearchParams } from 'react-router-dom';
import {turretStoreGetDepositLink} from "../apiConn.js"
import { useState, useEffect } from 'react';


function VideoArea(props) {

  const [videoIndex,setVideoIndex] = useState(0);

  var videos = [
      ["GiftedStrongMonitorKAPOW-3ivEUL6OPwePH7me","kingsammelot"],
      ["ThirstyCrackyCormorantRickroll-0X2F-P0fFvfDz08u","kingsammelot"],
      ["SparklyAgileNigiriOpieOP-s7HUcr9pXisgpcel","kingsammelot"]
    ]

  function previousVideo() {
    const idx = Math.max(0, videoIndex - 1)
    setVideoIndex(idx)
    console.log(videoIndex)
  }

  function nextVideo() {
    const idx = Math.min(videos.length - 1, videoIndex + 1)
    setVideoIndex(idx)
    console.log(videoIndex)
  }

  return (

    <div className="row px-4 justify-content-center py-3">
      <div class="col-auto order-2 order-sm-3 order-md-1 order-lg-1 order-xl-1 order-xxl-1">
            <button className="btn btn-light " onClick={previousVideo}>
              <img src="./streamturret-icons/icons8-chevron-left-100.png" width="30"></img>
            </button>
          </div>

          {(videoIndex > -1) && <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-6 order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2 order-xxl-2">
                        <iframe
                          src={"https://clips.twitch.tv/embed?clip=" + videos[videoIndex][0] + "&parent=www.streamturret.com"}
                          height="350"
                          width="100%">
                        </iframe>
            
                        <a href={"https://www.twitch.tv/"+ videos[videoIndex][1]} target="_blank">^^KingSammelot^^</a>
                      </div>}
          

          <div class="col-auto order-3">
            <button className="btn btn-light" onClick={nextVideo}>
              <img src="./streamturret-icons/icons8-chevron-right-100.png" width="30"></img>
            </button>
          </div>
        </div>
    )

}


export function StreamTurretHome(props) {

 


  var twitchURL = "https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=i48k75a6j36dxliez6ryyxpfzzg4a0&redirect_uri=https://streamturret.com:3000&state=c3ab8aa609ea11e793ae92361f002671&force_verify=true"
//scope=user%3Aread%3Aemail&
  const location = useLocation();
  const locPath = location.hash.split("#").pop()
  const params = locPath.split("&")
  const paramDict = {}

  



  console.log(paramDict)

  

  return (
    <div className="col-12 min-vh-100">



      <VideoArea></VideoArea>


      <div className="row px-5 py-4 justify-content-center">

      <div className="col-12 col-sm-12 col-md-8 col-lg-5 col-xl-4 col-xxl-3">

        <div className="row justify-content-center  px-4">
          <div className="col-12">
            <div className="row">
              <h3 className="store-title text-start">Stream Turret</h3>
            </div>
            <div className="row">
            <p className="store-body text-start">

              Followers pay to fire foam darts while you stream using our <a href="https://dashboard.twitch.tv/extensions/ulzmziygnlikww6wwmwwz2zt8j5gb7" target="_blank">extension</a>.

            </p>
            </div>

            <div className="row">
              <div className="col-12">



                <div className="row mb-3">
                  <Link to="/vote">
                  <button className="btn btn-purple">
                      Vote to win a Stream Turret for Your Favorite Streamer
                  </button>
                  </Link>
                  </div>





              </div>
            </div>


          </div>
        </div>

      </div>


      <div className="col-12 col-sm-12 col-md-8 col-lg-5 col-xl-4 col-xxl-3 bg-tan rounded border border-light">

        <img src="./turret_store.png" class="align-middle" style={{width:"80%"}}></img>


      </div>
      



      

        
      </div>

      


    </div>
  )

}
