import logo from './logo.svg';
import './App.css';

import {Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Route, Routes, Link, Navigate, useMatch, useLocation } from 'react-router-dom';
import { useState, useEffect, lazy, Suspense } from 'react';
import '@aws-amplify/ui-react/styles.css';
import './custom.css';
import { Auth } from 'aws-amplify';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import {StreamTurretHome} from "./Home/StreamTurretHome.js"
import {StreamTurretStore} from "./Home/StreamTurretStore.js"
import {StreamerVote} from "./Home/StreamerVote.js"
import usePromise from "react-promise";
import awsconfig from './aws-exports';
import { v4 as uuidv4 } from 'uuid';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import {TurretFiredImageView} from "./Home/TurretFiredImageView.js"


const Profile = lazy(() => import ('./Profile/Profile.js'))


awsconfig["API"] = {
  endpoints: [
      {
        name: "rover",
        endpoint: "https://slqdlhy2he.execute-api.us-east-2.amazonaws.com/develop",
        custom_header: async () => {
          return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: "rover",
        endpoint: "https://afvzs83quk.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }

Amplify.configure(awsconfig);
Amplify.addPluggable(new AWSIoTProvider({
   aws_pubsub_region: 'us-east-2',
   aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
 }));





function AppRouter(props) {
  const location = useLocation();

  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const pathArray = location.pathname.split("/")
    if (pathArray[1] == "rover") {
      var roverIdString = pathArray[2];
    }

  },[location])

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((signedInUser) => {
      const userId = signedInUser.username
      setLoggedIn(true)
      console.log("loggedIn")
    }).catch(err => {
      setLoggedIn(false)
    })

  },[location])




  return (
    <div className="App">
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">

        <a className="nav-link dark-link site-title ps-5 pe-auto" href="/">Stream Turret</a>
        <a className="nav-link purple-link me-auto" href="/vote">(Free Turrets)</a>
        <a className="d-none d-sm-none d-md-block" href="https://discord.gg/P6Egw4Wm" target="_blank"><img src="./streamturret-icons/icons8-discord-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.twitch.tv/streamturret" target="_blank"><img src="./streamturret-icons/icons8-twitch-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.instagram.com/streamturret" target="_blank"><img src="./streamturret-icons/icons8-instagram-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://twitter.com/streamturret" target="_blank"><img src="./streamturret-icons/icons8-twitter-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.linkedin.com/company/type-2-labs/" target="_blank"><img src="./streamturret-icons/icons8-linkedin-100.png" width="35"></img></a>
        <a className="ms-2 me-5 d-none d-sm-none d-md-block" href="mailto:support@type2labs.co" target="_blank"><img src="./streamturret-icons/icons8-email-100.png" width="35"></img></a>
      </div>
    </nav>
    <div className="container-fluid min-vh-100">

    <Suspense fallback={<div><p>Loading...</p></div>}>
    <Routes>
      <Route exact path='/' element={<StreamTurretHome />}/>
      <Route exact path='/vote' element={<StreamerVote />}/>
      <Route exact path='/turretImage/:twitchUserId' element={<TurretFiredImageView />}/>
      <Route exact path='/manage' element={<TurretConfigView />}/>
    </Routes>
    </Suspense>

    <div className="col-12">
      <div className="row d-block d-sm-block d-md-none my-5">
        <a className="col-auto" href="https://discord.gg/P6Egw4Wm" target="_blank"><img src="./streamturret-icons/icons8-discord-100.png" width="35"></img></a>
          <a className="col-auto" href="https://www.twitch.tv/streamturret" target="_blank"><img src="./streamturret-icons/icons8-twitch-100.png" width="35"></img></a>
          <a className="col-auto" href="https://www.instagram.com/streamturret" target="_blank"><img src="./streamturret-icons/icons8-instagram-100.png" width="35"></img></a>
          <a className="col-auto" href="https://twitter.com/streamturret" target="_blank"><img src="./streamturret-icons/icons8-twitter-100.png" width="35"></img></a>
          <a className="col-auto" href="https://www.linkedin.com/company/type-2-labs/" target="_blank"><img src="./streamturret-icons/icons8-linkedin-100.png" width="35"></img></a>
          <a className="col-auto" href="mailto:support@type2labs.co" target="_blank"><img src="./streamturret-icons/icons8-email-100.png" width="35"></img></a>
      </div>
    </div>

    </div>

    </div>
  )

}
function TurretConfigView() {
  return (
      <div className="row justify-content-center">
        <div className="col-auto">
        <div className="row store-title mb-4">
          Manage Live Stream Turret
        </div>
          <div className="row">
            <a href="https://dashboard.twitch.tv/popout/u/streamturret/extension-live-configure/ulzmziygnlikww6wwmwwz2zt8j5gb7:0.1.0">
              <button className="btn btn-purple">
                Stream Turret Live Config
             </button>
            </a>
            </div>
        </div>
      </div>
)
}


function App(props) {



  return (
    <BrowserRouter>
    <AppRouter/>
    </BrowserRouter>

  );
}

export default App;
