import { Auth, API } from 'aws-amplify';
import { BrowserRouter, Route, Routes, Link, Navigate, useMatch, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import {turretVoteGetVoterInfo, turretVoteFindStreamer, turretVoteVote} from "../apiConn.js"
import { useState, useEffect } from 'react';
import { streamTurretVoteCountByContestNumberAndTotalVotes } from '../graphql/queries';

export function StreamerVote(props) {

  const [didClickVerify, setDidClickVerify] = useState(false);
  const [didSearchStreamer, setDidSearchStreamer] = useState(true);
  const [searchWarning, setSearchWarning] = useState(null);
  const [voterInfo, setVoterInfo] = useState(null);
  const [didUseVote, setDidUseVote] = useState(true);
  const [nVotesAvailable, setNVotesAvailable] = useState(0)
  const [twitchAccessToken, setTwitchAccessToken] = useState(null);
  const [foundStreamer, setFoundStreamer] = useState(null);
  const [streamTurretVoteCounts, setStreamTurretVoteCounts] = useState(null);


  var twitchURL = "https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=i48k75a6j36dxliez6ryyxpfzzg4a0&redirect_uri=https://www.streamturret.com/vote&state=c3ab8aa609ea11e793ae92361f002671&force_verify=true"
  var twitchURLWithEmail = "https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=i48k75a6j36dxliez6ryyxpfzzg4a0&redirect_uri=https://www.streamturret.com/vote&state=c3ab8aa609ea11e793ae92361f002671&force_verify=true&scope=user%3Aread%3Aemail"
  const location = useLocation();
  const locPath = location.hash.split("#").pop()
  const params = locPath.split("&")
  const paramDict = {}
  const searchParams = new URLSearchParams(location.search)
  var streamerSearch = searchParams.get("streamer")



  if (!streamerSearch) {
    streamerSearch = window.localStorage.getItem('lastFoundStreamer')
  }


  const navigate = useNavigate()


  useEffect(() => {

    

    if (paramDict.access_token) {
      setTwitchAccessToken(paramDict.access_token)
    } else {
      console.log("set token",window.localStorage.getItem('twitchAccessToken'))
      setTwitchAccessToken(window.localStorage.getItem('twitchAccessToken'));
    }

    if (streamerSearch) {
      document.getElementById("streamerSearch").value = streamerSearch
      findStreamer()
    }

    
  }, []);

  useEffect(() => {
    console.log("accesstoken",twitchAccessToken)
    if (!twitchAccessToken) {
      return
    }

    window.localStorage.setItem('twitchAccessToken', twitchAccessToken);

    if (twitchAccessToken) {
      turretVoteGetVoterInfo(twitchAccessToken)
        .then(res => {
          console.log('res',res)
          setVoterInfo(res)
        })
      }
  }, [twitchAccessToken]);

  useEffect(() => {
    fetchStreamTurretVoteCounts()
  }, [navigate]);

  useEffect(() => {

    if (voterInfo) {
      setNVotesAvailable(voterInfo.votesAvailable)
    }
    
  }, [voterInfo]);



  params.forEach(param => {
    var p = param.split("=")
    paramDict[p[0]] = p[1]
  })

  console.log(paramDict)

  

  // if (!foundStreamer && streamerSearch) {
  //   turretVoteFindStreamer(streamerSearch)
  //   .then(res => {
  //     console.log(res)
  //     setFoundStreamer(res.voteCount)
  //   })
  // }

  


  function fetchStreamTurretVoteCounts() {
    return Auth.currentUserInfo().then(currentUserInfo => {
      var query = { query: streamTurretVoteCountByContestNumberAndTotalVotes, variables: {contestName: "streamTurret1", sortDirection: "DESC"}}
      console.log(currentUserInfo)
      if (!currentUserInfo) {
        query["authMode"] = 'AWS_IAM'
      }
      return API.graphql(query)
      .catch(err => {
        console.log(err)
        console.log(err.data.streamTurretVoteCountByContestNumberAndTotalVotes)
        if (err.data.streamTurretVoteCountByContestNumberAndTotalVotes.items.length > 0) {
          return Promise.resolve(err)
        } else {
          throw(err)
        }
      }).then(res => {
        const data = res.data.streamTurretVoteCountByContestNumberAndTotalVotes.items
        console.log("streamTurretVoteCountByContestNumberAndTotalVotes",data)
        setStreamTurretVoteCounts(data)
        return
      })
    })

  }
  console.log("voterInfo",voterInfo)


  function findStreamer() {
      const streamerName = document.getElementById("streamerSearch").value
      console.log(streamerName)
      turretVoteFindStreamer(streamerName)
      .then(res => {
        console.log(res)
        setFoundStreamer(res.voteCount)

        if (!res.authorized) {
          setSearchWarning(res.message)
        } else {
          setSearchWarning(null)
        }
        window.localStorage.setItem('lastFoundStreamer',streamerName);
        setDidSearchStreamer(!didSearchStreamer)
      })
  }

  function onVerify() {
    setDidClickVerify(true)
  }

  return (
    <div className="col-12 min-vh-100">


      <div className="row px-5 py-4 justify-content-center">
        
        <p className="store-title">Help Your Favorite Streamer Win</p>
        <p className="store-title2">The top 5 streamers will recieve a free Stream Turret. Voting Ends 9/4.</p>
        {/* <a href={twitchURL} style={{"color" : "#9146FF"}}>Verify Twtich Account to Vote</a> */}

      </div>


      {!voterInfo && 

        <div className="row justify-content-center mb-4">
              <div className="col-auto">
                <div className="row">
                  <button className="btn btn-purple" onClick={onVerify} >
                      Verify Twitch Account to Vote
                  </button>
                  </div>
              </div>
            </div>

      }

      

      {!voterInfo && didClickVerify && <div className="row justify-content-center mb-4">
              <div className="col-auto">
                <a href={twitchURLWithEmail} style={{"text-decoration" : "none"}}>
                <div className="row">
                  <button className="btn btn-primary" >
                      Verify & Provide Email (3 Votes)
                  </button>
                  </div>
                </a>
              </div>

              <div className="col-auto ms-1">
                <a href={twitchURL} style={{"text-decoration" : "none"}}>
                <div className="row">
                  <button className="btn btn-light-gray" >
                      Verify without Email (1 Vote)
                  </button>
                  </div>
                </a>
              </div>


            </div>
      }


      {!voterInfo && didClickVerify && <div className="row justify-content-center mb-4">
              <div className="col-4">
                <div className="store-fineprint">By clicking "Verify & Provide Email" you agree to receive email updates from Stream Turret and Type 2 Labs</div>
              </div>
            </div>
      }




     {(didUseVote || !didUseVote) && voterInfo && <div className="row justify-content-center">
             <div className="col-auto">
               <WelcomeMessage nVotesAvailable={nVotesAvailable} voterDisplayName={voterInfo.voterDisplayName}></WelcomeMessage>
             </div>
           </div>}


      <div className="row justify-content-center">
        <div className="col-10 col-sm-7 col-md-6 col-lg-6 col-xl-4 col-xxl-4">

        <div className="row justify-content-center mt-4 mb-4">
           <div className="col-auto store-title2-bold">Search for Streamers</div>
          </div>

        <div class="input-group mb-3">
              <input id="streamerSearch" name="streamer" type="text" class="form-control" placeholder="find streamer" aria-label="Streamer's Username" aria-describedby="button-addon2"></input>
              <button class="btn btn-primary" type="button" id="button-addon2" onClick={findStreamer}>Find</button>
            </div>


          {/* <form> */}
          {/*   <div class="input-group mb-3"> */}
          {/*     <input name="streamer" type="text" class="form-control" placeholder="find streamer" aria-label="Streamer's Username" aria-describedby="button-addon2"></input> */}
          {/*     <button class="btn btn-primary" type="button" id="button-addon2">Find</button> */}
          {/*   </div> */}
          {/* </form> */}
        </div>
        
      </div>


      {(didUseVote || !didUseVote) && foundStreamer && (didSearchStreamer || !didSearchStreamer) && 
        <div className="row justify-content-center">
          <div className="col-11 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <VoteCountItem voteCount={foundStreamer} voterInfo={voterInfo} accessToken={twitchAccessToken} setNVotesAvailable={setNVotesAvailable} nVotesAvailable={nVotesAvailable} setDidUseVote={setDidUseVote} didSearchStreamer={didSearchStreamer}/>
          </div>
        </div>
      }

      {searchWarning && 
        <div className="row justify-content-center">
          <div className="col-6">
            <p>{searchWarning}</p>
          </div>
        </div>
      }
      

      <div className="row justify-content-center mb-5">
        <div className="col-11 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

          <div className="row justify-content-center mt-4">
           <div className="col-auto store-title2-bold">Top Streamers</div>
          </div>

          {(didUseVote || !didUseVote) && streamTurretVoteCounts && voterInfo && streamTurretVoteCounts.map((voteCount) => (
            <VoteCountItem voteCount={voteCount} voterInfo={voterInfo} accessToken={twitchAccessToken} setNVotesAvailable={setNVotesAvailable} nVotesAvailable={nVotesAvailable} setDidUseVote={setDidUseVote}/>
          ))}


          {(didUseVote || !didUseVote) && streamTurretVoteCounts && !voterInfo && streamTurretVoteCounts.map((voteCount) => (
            <VoteCountItem voteCount={voteCount} voterInfo={voterInfo} accessToken={twitchAccessToken} setNVotesAvailable={setNVotesAvailable} nVotesAvailable={nVotesAvailable} setDidUseVote={setDidUseVote}/>
          ))}
        </div>
      </div>


    
        
      


    </div>
  )

}


function VoteCountItem(props) {
  const displayName = props.voteCount.streamerDisplayName
  const name = props.voteCount.streamerName
  const voteLink = "https://www.streamturret.com/vote?streamer=" + name
  const twitchLink = "https://twitch.tv/" + name


  const [nVotes, setNVotes] = useState(props.voteCount.totalVotes)


  useEffect(() => {
    setNVotes(props.voteCount.totalVotes)
  }, [props.didSearchStreamer]);


  function onVote() {

    if (props.voterInfo) {

      if (props.nVotesAvailable == 0) {
        return
      }

      setNVotes(nVotes + 1)

      props.setNVotesAvailable(props.nVotesAvailable - 1)
      props.setDidUseVote(true)

      return turretVoteVote(props.accessToken, props.voteCount.streamerId)

      .then(res => 
        console.log("didvote",res))


    }
    
  }

  // function copyVoteLink() {
  //   navigator.clipboard.writeText(voteLink)
  // }

  return (
    <div className="row justify-content-center mt-4">


      <div className="col-auto">
        <a className="ms-2" href={twitchLink} target="_blank"><img src="./streamturret-icons/icons8-twitch-100.png" width="24"></img></a>
      </div>


      <div className="col-auto store-body-bold me-auto"><div className="row text-start">
        <a className="dark-link" href={voteLink} target="_blank">{displayName}</a></div>
        </div>

      
{/*  */}
{/*       <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 store-body"><a href={voteLink} target="_blank">Vote Link</a></div> */}


      <div className="col-4">

        <button className="btn btn-primary" onClick={onVote} disabled={!props.voterInfo || props.voterInfo.votesAvailable == 0}>
          <div className="row">

              <div className="col store-body-white">
                {nVotes}
              </div>
              <div className="col d-flex align-items-vertical">
                <img src="./streamturret-icons/icons8-chevron-up-100.png" width="18"></img>
              </div>

              
              
               
          </div>
          
        </button>
        


      </div>
  </div>
  )



}


function WelcomeMessage(props) {

  console.log("nVotesAvailable",props.nVotesAvailable)

  return (<p className="store-subtitle-bold mb-4">Welcome, {props.voterDisplayName}! You have {props.nVotesAvailable} votes remaining</p>)
}









